import { Button } from "~/components/ui/button"
import { Link } from "@remix-run/react"
import { LogIn } from "lucide-react"
import { referralCodeCookie } from '~/cookies.server';
import hero from '~/images/hero.webp'
import type { LoaderFunctionArgs } from "@remix-run/node";


export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const referralCode = url.searchParams.get("referral");

  const headers = new Headers();

  if (referralCode) {
    headers.append(
      'Set-Cookie',
      await referralCodeCookie.serialize(referralCode)
    );
  }

  return new Response(null, {
    headers
  });
}

export default function Component() {
  return (
    <div className="bg-gradient-to-r from-pink-500 to-purple-500 min-h-screen flex flex-col items-center justify-center text-white p-4">
      <div className="max-w-4xl w-full text-center">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">Find Your Perfect Shopify AppMatch</h1>
        <p className="text-xl md:text-2xl">Swipe right to connect, swipe left to pass. It's that simple!</p>

        <div className="relative w-72 h-96 mx-auto">
          <img
            src={hero}
            alt="Card stack"
            className="w-full h-full object-contain"
          />
        </div>

        <div className="flex flex-col items-center space-y-4">
          <Button asChild className="bg-white text-gray-800 hover:bg-gray-100 rounded-lg py-2 px-4 flex items-center justify-center shadow-lg transition duration-300 w-64">
            <Link to="/auth/google">
              {/* Google Icon */}
              <svg className="w-6 h-6 mr-2" viewBox="0 0 24 24">
                <path
                  fill="#4285F4"
                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                />
                <path
                  fill="#34A853"
                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                />
                <path
                  fill="#FBBC05"
                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                />
                <path
                  fill="#EA4335"
                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                />
                <path fill="none" d="M1 1h22v22H1z" />
              </svg>
              Sign up with Google
            </Link>
          </Button>
          <Button asChild className="bg-purple-600 text-white hover:bg-purple-700 rounded-lg py-2 px-4 flex items-center justify-center shadow-lg transition duration-300 w-64">
            <Link to="/auth/google">
              <LogIn className="w-5 h-5 mr-2" />
              Login
            </Link>
          </Button>
          <p className="text-sm">
            By signing up, you agree to our{" "}
            <Link to="/terms" className="underline hover:text-gray-200 transition duration-300">
              Terms & Privacy policy
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}